import React from 'react';
import {Typography} from '@material-ui/core';
import {locationResultStyle} from "./LocationResult";
import {Skeleton} from "@material-ui/lab";

export default function LocationResult() {
    const classes = locationResultStyle(undefined);
    const one = Math.floor(Math.random()*(8-3+1)+ 3);
    const two = Math.floor(Math.random()*(8-3+1)+ 3);
    return (
        <div className={classes.root}>
            <div className={classes.icon}>
                <Skeleton width={38} height={38} component={'div'}/>
            </div>
            <div className={classes.textResult}>
                <Typography variant={'body2'} component={'div'}>
                    <Skeleton width={`${one}${two}%`} />
                </Typography>
            </div>
        </div>
    );
}