import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {MapPin} from 'react-feather';

export const locationResultStyle = makeStyles(theme => ({
    icon: {
        position: 'relative',
        width: 38,
        height: 38,
        border: '1px solid #DDDD',
        backgroundColor: '#F2F2F2',
        marginRight: 10,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiSkeleton-text': {
            transform: 'none'
        }
    },
    textResult: {
        width: 'calc(100% - 48px)'
    },
    root: {
        paddingBottom: 16,
        paddingTop: 4,
        marginBottom: 16,
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.divider}`,
        transitionDuration: '.35s',
        cursor: (props ?: LocationResultProps) => !!props ? 'pointer' : 'default',
        '&:hover': {
            borderBottom: (props ?: LocationResultProps) => !!props ?
                `1px solid ${theme.palette.primary.main}`
                : `1px solid ${theme.palette.divider}`,
            // color: theme.palette.primary.main
        }
    }
}));

interface LocationResultProps {
    title: string;
    keyword: string;
}

export default function LocationResult(props: LocationResultProps) {
    const classes = locationResultStyle(props);
    return (
        <div className={classes.root}>
            <div className={classes.icon}>
                <MapPin size={14} color={'#4F4F4F'}/>
            </div>
            <div className={classes.textResult}>
                <Typography variant={'body2'} component={'span'}>
                    {props.title.slice(0, props.title.toLowerCase().indexOf(props.keyword.toLowerCase()))}
                </Typography>
                <Typography variant={'body1'} component={'span'}>
                    {props.keyword}
                </Typography>
                <Typography variant={'body2'} component={'span'}>
                    {props.title.slice(
                        props.title.toLowerCase().indexOf(props.keyword.toLowerCase()) +
                        props.keyword.length,
                        props.title.length
                    )}
                </Typography>
            </div>
        </div>
    );
}