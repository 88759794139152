import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {TextField, InputAdornment, Box, LinearProgress} from '@material-ui/core';
import {Search} from 'react-feather'
import useTranslation from 'next-translate/useTranslation';
import {LocationResult, LocationResultShimer} from "./components";
import {GoogleService} from "../../../service/api";
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState, Location} from "../../../store/types";
import Link from "next/link";
import {setMapKeyword, setMapLocations} from "../../../store/actions/MapConfigActions";
import {useRouter} from "next/router";
import * as ga from 'ga/actions'

const useStyles = makeStyles(theme => ({}));

export default function SearchLocation() {
    const classes = useStyles();
    const {t} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [timerId, setTimerId] = useState<any | undefined>();
    const mapState = useSelector((state: ApplicationState) => state.mapConfig);
    const {locations, keyword} = mapState;
    const dispatch = useDispatch();
    const setKeyword = (keyword: string) => dispatch(setMapKeyword(keyword));
    const setLocations = (locations: Location[]) => dispatch(setMapLocations(locations));
    const [mounted, setMounted] = useState(false);
    const router = useRouter();
    const {filter} = router.query;

    useEffect(() => {
        if (mounted) {
            if (keyword.length > 0) {
                if (timerId) clearTimeout(timerId);
                setTimerId(setTimeout(fetchLocations, 500));
            }
        } else
            setMounted(true);
    }, [keyword]);
    const fetchLocations = () => {
        setLoading(true);
        GoogleService.fetch_place(keyword, mapState.mapConfig)
            .then(async (response: any) => {
                if (response.status === 200 || response.status === 201) {
                    const data = await response.json();
                    setLocations(data.map((i: any) => ({
                        label: i.description,
                        id: i.place_id,
                    })));
                }
            })
            .catch((err: any) => {

            })
            .finally(() => setLoading(false));
    }
    return (
        <Box component={'div'}>
            <Box mb={2}>
                <TextField
                    fullWidth
                    value={keyword}
                    onChange={e => {
                        e.persist();
                        setKeyword(e.target.value);
                    }}
                    placeholder={t('common:home_exemple_try')}
                    InputProps={{
                        startAdornment: <InputAdornment position={'start'}>
                            <Search/>
                        </InputAdornment>
                    }}
                    variant={'outlined'}
                />
            </Box>
            {
                loading ?
                    Array.from(new Array(5)).map((i, k) => <LocationResultShimer key={k}/>)
                    :
                    locations.map(i =>
                        <Link key={i.id} href={`/search_loading?q=${i.label}&place_id=${i.id}${filter ? `&filter=${filter}` : ''}`} prefetch={true}>
                            <a
                                onClick={() => {
                                    ga.event("SearchInLocation", {
                                        location: i.label,
                                        place_id: i.id,
                                        query: `q=${i.label}&place_id=${i.id}${filter ? `&filter=${filter}` : ''}`
                                    })
                                }}
                                style={{
                                color: 'inherit',
                                textDecoration: 'none'
                            }}>
                                <LocationResult title={i.label} keyword={keyword}/>
                            </a>
                        </Link>
                    )
            }
        </Box>
    );
}
