
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Head from 'next/head'
import {Box, Typography} from "@material-ui/core";
import {SearchLocation} from "../src/page_components/home/index";
import {makeStyles} from "@material-ui/core/styles";
import useTranslation from 'next-translate/useTranslation'
import React from "react";
import ShareLinkDialog from "../src/common_components/ShareLinkDialog";


const useStyles = makeStyles(theme => ({
    root: {
        width: 350,
        margin: '0 auto',
        maxWidth: "100%"
    }
}));


export default function Index() {
    const classes = useStyles();
    const {t, lang} = useTranslation();

    return (
        <div className={classes.root}>
            <Head>
                <title>{t('common:home_title')}</title>
                <meta
                    name="description"
                    content={t('common:home_meta_description')}
                />
                <link rel="icon" href="/logo.png"/>
            </Head>
            <main>
                <Box mb={4} component={'div'} mt={2}>
                    <Typography variant={'h3'} align={'left'}>
                        {t('common:where_to_go')}
                    </Typography>
                </Box>
                <SearchLocation/>
            </main>
            <script type="application/ld+json" dangerouslySetInnerHTML={{
                __html: JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "WebSite",
                    "url": process.env.NEXT_PUBLIC_REACT_APP_SERVER,
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": `${process.env.NEXT_PUBLIC_REACT_APP_SERVER}/search?q={search_term_string}`,
                        "query-input": "required name=search_term_string"
                    }
                })
            }}/>

        </div>
    )
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  